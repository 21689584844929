import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProductsDetail() {
  // Use toast
  const toast = useToast();

  const fetchProductByID = (product) => {
    const productID = product.value.ID;
    store
      .dispatch("app-product/fetchProductByID", { productID })
      .then((response) => {
        product.value = response.data;
      })
      .catch(() => {
        product.value = undefined;
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching product detail",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    fetchProductByID,
  };
}
