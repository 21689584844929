<template>
  <section class="app-itemgrid-details mt-1">
    <b-row class="match-height" align-h="end" v-if="product !== undefined">
      <b-col cols="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'product-edit', params: { id: product.ID } }"
        >
          <feather-icon icon="EditIcon" class="mr-50" />
          <span>Editer</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'product-list' }">
          Product List
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card v-if="product !== undefined" class="card-product" no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                v-if="product.logo"
                :src="`${publicPath}` + `images/products/` + `${product.logo}`"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ product.name }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <b-link class="company-name">
                <h4 class="item-price mr-1">
                  {{ product.category }}
                </h4>
                {{ product.version }}
              </b-link>
            </b-card-text>

            <!-- Avability -->
            <b-card-text
              >Statut -
              <span class="text-success">{{
                product.status
              }}</span></b-card-text
            >

            <!-- Product Description -->
            <b-card-text>{{ product.description }}</b-card-text>

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <ul class="itemgrid-features list-unstyled">
              <li>
                <feather-icon icon="GlobeIcon" />
                <span>{{ product.URL }}</span>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useProductsDetail from "./useProductsDetail";
import productStoreModule from "../productStoreModule";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BAlert,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  mixins: [PageBoxed],
  setup() {
    const product = ref({});

    const PRODUCT_APP_STORE_MODULE_NAME = "app-product";

    const { fetchProductByID } = useProductsDetail();

    // Pull productID from router
    const { route } = useRouter();
    const productID = route.value.params.id;

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME);
    });

    product.value.ID = productID;
    fetchProductByID(product);

    return {
      product,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid-details.scss";

.product-img {
  height: 12vw;
  object-fit: cover;
}

.card-product {
  margin-top: 1rem;
}
</style>
